.rg-checkmark { 
    fill: var(--alethea-green);
    position: absolute;
}

.rg-checkmark-container {
    left: 4px;
    top: 4px;
    position: absolute;
    z-index: 1;
}

.rg-checkmark-circle-outer { 
    fill: var(--alethea-green);
    position: absolute; 
}
.rg-checkmark-circle-inner { 
    fill: white;
    position: absolute; 
}


.rg-more-info{
    right: 2px;
    bottom: 6px;
    position: absolute;
    z-index: 1;
    color: var(--alethea-green);
}


.rg-play{
    right: 4px;
    top: 4px;
    position: absolute;
    z-index: 1;
    color: var(--alethea-green);
}


.rg-gallery-container {
    max-height: 70vh; /* 70% of screen height */
    overflow-y: auto;
    overflow-x: hidden;
}

.rg-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 5vh;
}
.rg-image-container {
    background-color: #eee;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    height: 25%;
    width: 25%;
}

.rg-video-container {
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    height: 25%;
    width: 25%;
}


.rg-image-container:hover, .rg-video-container:hover{
    outline:2px solid var(--alethea-green)
}


.rg-image {
    width: 100%;
    height: 100%;
}

.rg-unselected-image {
    transition: transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s;
}

.rg-selected-image {
    transition: transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s;
}