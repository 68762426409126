:root {
  --alethea-green: #05707d;
  --alethea-orange: #f36c24;
  --alethea-light-green: #86d0c8;
  --default-grey: #555555;
  --light-grey: #dddddd;
  --danger-red: #dc3545;
  --disabled-color: #E9ECEF;
}


.heading {
  font-family: Tahoma, "Segoe UI", "Arial" !important;
}

.tooltip-hover {
  color: var(--alethea-green);
  cursor: pointer;
}

/*Fade out animation*/
.animated {
  -webkit-animation-duration: 0.5s;animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
/*Fade out animation*/

.button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: var(--alethea-green);
}


.button:focus {
  outline:0;
}

.button-circle {
  width: 20px;
  height: 20px;
  /* text-align: center;
  font-size: 12px;
  line-height: 1.428571429; */
  border-radius: 15px;
  background: var(--alethea-green);
  color: #ffffff;
  border: none;
  display: flex;
}

.button-circle:disabled {
  background-color: #aaaaaa;
}

.button-border {
  border: none;
  padding: 0.6em;
  border-radius: 0.25em;
  background: var(--alethea-green);
  color: #ffffff;
  font-size: 0.9em;
}
.danger {
  background: var(--danger-red);
}

.button-border:disabled {
  background: #aaaaaa;
}

.button-material {
  border: none;
  background: none;
  color: var(--alethea-green);
}

/* Remove the outline when you click on a material button */
.button-material:focus {
  outline: none;
}

.button-material:disabled {
  color: #aaaaaa;
}

.card {
  /* filter: drop-shadow(0px 1px 0px var(--light-grey)); */
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 8px solid transparent;
  align-items: center;
  transition: all 0.1s 0s;
  border-top-color: var(--default-grey);
} 
.caret.down{
  transform: rotate(0);
}
.caret.right{
  transform: rotate(-90deg);
}

.centered-text {
  text-align: center
}

.checkbox {
  margin-right: 0.5em;
}

.remove-button {
  margin-top: 0.3em;
  padding: 0;
  /* color: white; */
}

.add-button {
  margin-right: 0.5em;
  margin-bottom: 0.10em;
  margin-top: 0.1em !important;
}

.covid-form-row-element {
  margin: 0em;
  padding: 0em;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 100px;
  border-width: 2px;
  border-color: var(--light-grey);
  border-style: dashed;
  border-radius: 5px;
  padding: 5px;
}

.dropzone-text {
  align-items: center;

}

.email-error, .phn-error {
  margin: 0.5em
}

.email-body-textarea {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  max-height: 500px;
  resize: both;
}

.form-textarea {
  resize: both;
}

.error-tooltip {
  background-color: none;
  color: rgba(255, 100, 100, 0.85);
  font-size: 0.8em;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.form-control[readonly].status-text{
  background-color: white;
}

.form-sender-delay-check {
  margin-top: 0.9em;
}

fieldset:disabled input {
  background: var(--disabled-color);
}

.header-item {
  width: 0%;
  margin: 0 auto;
}

.header-image {
  margin-top: 5em
}

.header-text {
  text-align: center;
  color: var(--default-grey);
}

.location-list {
  max-height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
}


.login-button {
  color: var(--default-grey);
  font-weight: 500;
}

.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}


/* Remove transition animations on dropdowns */
.MuiMenu-paper {
  transition-duration: 0s !important;
}


.user {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: var(--alethea-green);
  font-weight: 600;
}


.section-header{  
  background-color: white;
  color: var(--default-grey);
}

.spacer {
  margin-top: 40px; /* define margin as you see fit */
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.symptoms-card {
  padding-right: 0;
}



.signoff-textarea {
  resize: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
}

.tooltip {
  font-size: 1.5em;
}

.user {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: var(--default-grey);
}
