.thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border: none;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box
}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    position: relative;
}

.thumb-img {
    display: block;
    width: auto;
    height: 100%
}

.thumb-video {
    display: block;
    width: auto;
    height: 100%
}

.thumb-img-remove-button {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white;
}