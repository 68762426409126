.g-checkmark { 
    fill: var(--alethea-green);
    position: absolute;
}

.g-checkmark-editing {
    left: 6px;
    top: 6px;
    position: absolute;
    z-index: 1;
}

.g-checkmark-not-editing {
    display: none;
}
.g-checkmark-circle-outer { 
    fill: var(--alethea-green);
    position: absolute; 
}
.g-checkmark-circle-inner { 
    fill: white;
    position: absolute; 
}

.g-image-container {
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    height: 10%;
    width: 10%;
}
.gallery-container {
    max-height: 70vh; /* 70% of screen height */
    overflow-y: auto;
    overflow-x: hidden;
}

.g-video-container {
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    height: 10%;
    width: 10%;
}

.g-image-container:hover, .g-video-container:hover {
    outline:2px solid var(--alethea-green)
}

.g-image {
    width: 100%;
    height: 100%;
}

.g-modal-video {
    width: 100%;
    height: 100%;
    margin: auto;
}

.g-editing-image {
    transition: transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s;
}

.g-not-editing-image {
    transition: transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s;
}

.g-play{
    right: 4px;
    top: 4px;
    position: absolute;
    z-index: 1;
    color: var(--alethea-green);
}


  
